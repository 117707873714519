import * as React from "react";
import logo from "./LIBRARY_CITY_LOGO.png";
import "./Home.css";

export default function Home() {
  return (
    <div>
      <div className="city-logo-container">
        <img className="city-logo-it" src={logo} alt="cit it logo" />
      </div>
      <div className="notice-title-container">
        <h1>Pay Your Fines Online Using SmartPAY</h1>
      </div>
      <div className="notice-message-container">
        <p>
          Now you can{" "}
          <a href="https://discovery.roundrocktexas.gov/MyAccount/Home">
            pay any fines
          </a>{" "}
          directly from your online library account using the seamlessly
          integrated online payment tool, SmartPAY.&nbsp; When you log in to
          your account, you will see your fines on the left-side of the page.
        </p>
        <p>
          <a
            className="learn-more-button"
            href="https://www.roundrocktexas.gov/news/new-pay-your-fines-online-using-smartpay/"
          >
            Click here to learn more
          </a>
        </p>
      </div>
    </div>
  );
}
