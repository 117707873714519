import React, { Component } from "react";
import Home from "./Home";
import { Routes, Route } from "react-router-dom";
import "./App.css";

class App extends Component {
  render() {
    return (
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/*" element={<Home />} />
      </Routes>
    );
  }
}

export default App;
